import { useEffect } from 'react';

type Options = {
  async: boolean;
  id?: string;
  parentNode: HTMLElement | null;
  tag?: any;
  type: string;
};

export const addVendorScript = (src: string, options: Partial<Options> = {}) => {
  options = {
    async: false,
    tag: 'script',
    type: 'text/javascript',
    parentNode: null,
    ...options,
  } as Options;

  options.parentNode = options.parentNode ||
    (document.getElementsByTagName(options.tag)[0] || {}).parentNode ||
    document.body;

  return new Promise((resolve, reject) => {
    const element = document.createElement(options.tag);
    let done = false;

    element.src = src;
    element.async = !!options.async;
    element.type = options.type;

    if (options.id) {
      element.id = options.id;
    }
    
    // Attach handlers for all browsers.
    // References:
    // https://github.com/MiguelCastillo/load-js/blob/master/src/load-js.js
    // http://stackoverflow.com/questions/4845762/onload-handler-for-script-tag-in-internet-explorer
    element.onload = element.onreadystatechange = () => {
      if (!done && (!element.readyState || element.readyState === 'loaded' || element.readyState === 'complete')) {
        done = true;
        // Handle memory leak in IE
        element.onload = element.onreadystatechange = null;
        resolve(element);
      }
    };
    element.onerror = reject;

    if (!options.parentNode) {
      throw new Error('Parent node is not defined');
    }

    options.parentNode.appendChild(element);
  });
};

const GreenHouse = () => {
  useEffect(() => {
    addVendorScript('https://boards.greenhouse.io/embed/job_board/js?for=wheniwork')
      // @ts-ignore
      .then(() => window.Grnhse.Iframe ? window.Grnhse.Iframe.load() : null);
  }, []);

  return (
    <div id="grnhse_app"></div>
  );
};

export default GreenHouse;
