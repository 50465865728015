import { ReactNode } from 'react';

import './styles/sticky-cta.scss';

type StickyCtaProps = {
  children: ReactNode,
  className: string
}
const StickyCta = ({ className, children }:StickyCtaProps) => (
  <section
    data-section="fixed bottom cta"
    className={ `sticky-cta-section ${className || ''}` }
  >
    {children}
  </section>
);


export default StickyCta;
